import { Router } from '@angular/router';
import { AppConfigService } from '../app-config.service';
import { SignupService } from '../signup.service';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(configService, service, router) {
        this.configService = configService;
        this.service = service;
        this.router = router;
        this.languageDropdownOpen = false;
    }
    Object.defineProperty(HeaderComponent.prototype, "gratisalLink", {
        get: function () {
            if (this.configService.config.hideFreeFeatures) {
                return 'http://www.gratisal.gl/';
            }
            return 'http://gratisal.dk/' + this.service.language;
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.changeLanguageTo = function (language) {
        this.languageDropdownOpen = false;
        this.router.navigate([language]);
    };
    return HeaderComponent;
}());
export { HeaderComponent };
