var FeaturesComponent = /** @class */ (function () {
    function FeaturesComponent() {
        this.language = 'en';
    }
    Object.defineProperty(FeaturesComponent.prototype, "signupPriceLink", {
        get: function () {
            switch (this.language) {
                case 'da':
                    return 'http://www.gratisal.dk/da/priser/';
                case 'en':
                    return 'http://www.gratisal.dk/en/prices/';
                case 'kl':
                    return 'http://www.gratisal.dk/kl/priser/';
                default:
                    return 'http://www.gratisal.dk/da/priser/';
            }
        },
        enumerable: true,
        configurable: true
    });
    return FeaturesComponent;
}());
export { FeaturesComponent };
