import * as i0 from "@angular/core";
var ModalService = /** @class */ (function () {
    function ModalService() {
        this.title = '';
        this.message = '';
        this.alertVisible = false;
    }
    ModalService.prototype.alert = function (title, message) {
        this.title = title;
        this.message = message;
        this.alertVisible = true;
    };
    ModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalService_Factory() { return new ModalService(); }, token: ModalService, providedIn: "root" });
    return ModalService;
}());
export { ModalService };
