import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import * as Raven from 'raven-js';
import { environment } from 'src/environments/environment';
var RavenErrorHandler = /** @class */ (function (_super) {
    tslib_1.__extends(RavenErrorHandler, _super);
    function RavenErrorHandler() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RavenErrorHandler.prototype.handleError = function (error) {
        if (error.message === 'GSNetworkError' ||
            error.status === 0 ||
            (error.status && (error.status === 400 || error.status === 401 || error.status === 404))) {
            return;
        }
        if (error.message === 'GSNetworkError' ||
            (error.rejection &&
                (error.rejection.status === 0 ||
                    (error.rejection.status &&
                        (error.rejection.status === 400 || error.rejection.status === 401 || error.rejection.status === 404))))) {
            return;
        }
        if (error && error.message && error.message.indexOf('a[b].target.className.indexOf') >= 0) {
            return;
        }
        if (error && error.message && error.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') >= 0) {
            return;
        }
        Raven.captureException(error.originalError || error);
        if (!environment.production) {
            console.error(error);
            _super.prototype.handleError.call(this, error);
        }
    };
    return RavenErrorHandler;
}(ErrorHandler));
export { RavenErrorHandler };
