import { OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { ModalService } from '../modal.service';
import { CompanyCreationRequest } from '../model';
import { SignupService } from '../signup.service';
var SignupComponent = /** @class */ (function () {
    function SignupComponent(configService, service, modalService, route, titleservice, translate) {
        this.configService = configService;
        this.service = service;
        this.modalService = modalService;
        this.route = route;
        this.titleservice = titleservice;
        this.translate = translate;
        this.language = 'en';
        this.value = new CompanyCreationRequest();
        this.newCompanyId = 0;
        this.isBusy = false;
        this.submitting = false;
        this.alreadyHaveAccountDialogVisible = false;
        this.passwordExplanationDialogVisible = false;
        this.representInfoDialogVisible = false;
        this.startupOfferDialogVisible = false;
        this.unknownCompanyDialogVisible = false;
        this.invalidCprDialogVisible = false;
        this.checkboxValidationDialogVisible = false;
        this.unexpectedErrorDialogVisible = false;
        this.signupErrorDialogVisible = false;
        this.signupErrorMessage = '';
        this.signupConfirmationDialogVisible = false;
        this.NoteForSignupDialogVisible = false;
        this.cvrExists = false;
        this.vatRegistrationNumberValid = true;
        this.companyNameValid = true;
        this.attpersonValid = true;
        this.emailValid = true;
        this.noCPRNumber = false;
        this.validCPR = true;
        this.hidePassword = false;
        this.showPassword = false;
        this.passwordValid = true;
        this.termsChecked = false;
        this.otherReferral = '';
    }
    Object.defineProperty(SignupComponent.prototype, "newAccountNameHint", {
        get: function () {
            if (this.value.AccountTypeId) {
                switch (this.value.AccountTypeId) {
                    case 1:
                        return 'SignUpForm.NameOfAccountantFirm';
                    case 2:
                        return 'SignUpForm.NameOfPayrollAgency';
                    case 3:
                        return 'SignUpForm.NameOfCorporation';
                    default:
                        break;
                }
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    SignupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translate.use('da').subscribe(function () {
            // default cache and download lang 'da' json
            _this.route.paramMap.subscribe(function (param) {
                var language = param.get('language');
                _this.service.language = language || 'da';
                _this.translate.use(_this.service.language).subscribe(function () {
                    _this.value.SubscribeToNewsLetter = true;
                    _this.translateText();
                });
            });
        });
    };
    SignupComponent.prototype.translateText = function () {
        var _this = this;
        this.translate.get(['Title']).subscribe(function (translations) {
            _this.titleservice.setTitle(translations.Title);
        });
    };
    SignupComponent.prototype.closealldialog = function () {
        if (this.signupConfirmationDialogVisible) {
            this.onShowLoginTab();
            return;
        }
        this.passwordExplanationDialogVisible = false;
        this.unknownCompanyDialogVisible = false;
        this.invalidCprDialogVisible = false;
        this.signupConfirmationDialogVisible = false;
        this.checkboxValidationDialogVisible = false;
        this.unexpectedErrorDialogVisible = false;
        this.signupErrorDialogVisible = false;
        this.startupOfferDialogVisible = false;
        this.alreadyHaveAccountDialogVisible = false;
        this.representInfoDialogVisible = false;
        this.NoteForSignupDialogVisible = false;
        this.modalService.alertVisible = false;
    };
    SignupComponent.prototype.onmodalclick = function (event) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
    };
    SignupComponent.prototype.onNoteForSignupDialogVisible = function () {
        this.NoteForSignupDialogVisible = true;
        setTimeout(function () {
            var elementCall = document.querySelectorAll('.NoteForSignupMessageCallLink');
            elementCall.forEach(function (item) {
                item.addEventListener('click', function () {
                    window.location.href = item.href;
                });
            });
        });
    };
    SignupComponent.prototype.onShowLoginTab = function () {
        window.location.href = '/login';
    };
    SignupComponent.prototype.checkCVR = function () {
        var _this = this;
        if (this.value.VatRegistrationNumber.length === 0) {
            this.vatRegistrationNumberValid = false;
            return;
        }
        this.submitting = true;
        this.service
            .getCompanyByCvr(this.value.VatRegistrationNumber)
            .pipe(finalize(function () { return (_this.submitting = false); }))
            .subscribe(function (data) {
            _this.value.CompanyName = data.Name || '';
            if (_this.value.CompanyName) {
                _this.companyNameValid = true;
                _this.emailValid = true;
            }
            if (data.Owners && data.Owners.length > 0) {
                _this.value.ResponsibleUserFullName = data.Owners[0].Name || '';
            }
            if (_this.value.ResponsibleUserFullName) {
                _this.attpersonValid = true;
            }
            _this.cvrExists = data.ExistsInGratisal;
            _this.value.PhoneNumber = data.Phone || '';
        }, function (error) {
            if (error.status === 404) {
                _this.unknownCompanyDialogVisible = true;
            }
        });
    };
    SignupComponent.prototype.checkCPR = function () {
        var _this = this;
        this.validCPR = this.value.ResponsibleUserIdentityNumber !== '';
        this.submitting = true;
        var existsInGratisal = false;
        this.service
            .checkIdentityNumber(this.value.ResponsibleUserIdentityNumber)
            .pipe(finalize(function () {
            _this.submitting = false;
            _this.hidePassword = existsInGratisal;
        }))
            .subscribe(function (data) {
            existsInGratisal = data.ExistsInGratisal;
            _this.validCPR = data.IsWellFormed;
        }, function (error) {
            if (error.status === 404) {
                return;
            }
            _this.invalidCprDialogVisible = true;
        });
    };
    SignupComponent.prototype.checkPassword = function () {
        var _this = this;
        if (this.value.NewUserPassword.length === 0) {
            this.passwordValid = true;
            return;
        }
        this.submitting = true;
        this.service
            .validatePassword(this.value.NewUserPassword)
            .pipe(finalize(function () { return (_this.submitting = false); }))
            .subscribe(function (data) { return (_this.passwordValid = data); });
    };
    SignupComponent.prototype.signup = function () {
        var _this = this;
        if (!this.checkRequiredFields()) {
            return;
        }
        this.value.KnowsGratisalFrom =
            this.service.selectedReferral &&
                this.service.selectedReferral.AdditionalInfoRelevant &&
                this.otherReferral &&
                this.otherReferral.length > 0
                ? this.otherReferral
                : this.service.knowGratisalFrom;
        this.value.LanguageId = this.language === 'da' ? 1 : 2;
        this.submitting = true;
        this.isBusy = true;
        this.service
            .signup(this.value)
            .pipe(finalize(function () {
            _this.submitting = false;
            _this.isBusy = false;
        }))
            .subscribe(function (data) {
            _this.newCompanyId = data.Id;
            gtag_report_conversion();
            _this.signupConfirmationDialogVisible = true;
        }, function (error) {
            if (error.status === 400) {
                _this.signupErrorMessage = error.error.Message;
                _this.signupErrorDialogVisible = true;
            }
            else {
                _this.unexpectedErrorDialogVisible = false;
            }
        });
    };
    SignupComponent.prototype.checkRequiredFields = function () {
        var result = true;
        this.vatRegistrationNumberValid = true;
        if (this.value.VatRegistrationNumber.trim() === '') {
            this.vatRegistrationNumberValid = false;
            result = false;
        }
        this.companyNameValid = true;
        if (this.value.CompanyName.trim() === '') {
            this.companyNameValid = false;
            result = false;
        }
        this.attpersonValid = true;
        if (this.value.ResponsibleUserFullName.trim() === '') {
            this.attpersonValid = false;
            result = false;
        }
        this.emailValid = true;
        if (this.value.ResponsibleUserEmail.trim() === '') {
            this.emailValid = false;
            result = false;
        }
        if (!this.termsChecked) {
            this.checkboxValidationDialogVisible = true;
            result = false;
        }
        return result;
    };
    return SignupComponent;
}());
export { SignupComponent };
